@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
.webinar-details {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 160px);
    text-align: left;
    font-family: "Noto Sans", sans-serif;
    overflow-x: hidden;
    overflow-y: auto;
}
.webinar-details::-webkit-scrollbar {
    display: none;
}
.det {
    width: 90%;
}
.webinar-name {
    font-size: 2rem;
    font-weight: 600;
    font-family: "Noto Sans Mono", monospace;
    color: #43aa8b;
}
.webinar-domain {
    color: #577590;
}
.webinar-date {
    margin-bottom: 1rem;
    color: #577590;
}
.webinar-desc {
    white-space: pre-wrap;
    text-align: left;
    border-top: solid 1px #118ab2;
    padding-top: 1rem;
}
#webinar-btn {
    text-transform: none;
    width: 40vw;
    background-color: #118ab2;
    color: #fff;
    font-size: 1rem;
}
.webinar-reg-form {
    margin-bottom: 2rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Noto Sans Mono", monospace;
}
.webinar-reg-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 120px);
}
.form-start {
    width: 40vw;
    margin-bottom: 1.3rem;
    font-size: 1.4rem;
    font-weight: 600;
    font-family: "Noto Sans", sans-serif;
}
.loading-page {
    width: 100vw;
    height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.invalid-form {
    width: 100%;
    text-align: center;
    font-family: "Noto Sans Mono", monospace;
    font-weight: 600;
    font-size: 1.4rem;
    color: #ff725e;
}
@media screen and (max-width: 768px) {
    .webinar-reg-wrapper {
        height: fit-content;
    }
    #webinar-btn {
        width: 80vw;
    }
    .form-start {
        width: 80vw;
    }
    .webinar-details {
        min-height: 50vh;
        height: fit-content;
    }
    .det {
        width: 80vw;
    }
}
