@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: calc(100vh - 120px);
    width: 100vw;
}
@media screen and (max-width: 768px) {
    .main-container {
        min-height: calc(100vh - 250px);
    }
}

.topbar {
    width: 100%;
    height: 70px;
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
}

.topbarWrapper {
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-logo {
    margin-right: 1.1rem;
}
.top-text {
    font-weight: bold;
    font-size: 2rem;
    color: #000;
    cursor: pointer;
}

.topRight {
    display: flex;
    align-items: center;
}
.topLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}
.topbarIconContainer {
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;
}

.topIconBadge {
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.topAvatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    cursor: pointer;
}
.top-logout {
    cursor: pointer;
    margin-left: 1rem;
}
.top-logout:hover {
    color: #4169e1;
}
.top-details {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid;
}
.top-details > div {
    margin-right: 1rem;
}
.top-name {
    font-size: 1.1rem;
    font-weight: 600;
}
.top-domain {
    font-size: 0.9rem;
}
@media screen and (max-width: 600px) {
    .top-text {
        font-weight: 500;
        font-size: 1.5rem;
    }
    .top-user {
        display: none;
    }
}

.footer-container {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-evenly;
    align-items: center;
    font-size: 0.9rem;
    background-color: #000;
    color: #edf6f9;
    font-family: "Ubuntu", sans-serif;
}
.footer-container > div {
    width: 30%;
}
.copyright-content,
.made-by {
    font-family: "Ubuntu", sans-serif;
    text-align: center;
}
.footer-socials {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    justify-content: space-evenly;
}
/* .footer-socials > div {
	margin-right: 20px;
} */
.footer-socials > div:hover {
    color: #f77f00;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.heart {
    color: red;
}
@media only screen and (max-width: 950px) {
    .footer-container {
        flex-direction: column;
        height: 150px;
    }
    .footer-container > div {
        width: 100%;
    }
    .footer-socials {
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .footer-socials > div {
        margin-right: 0px;
        color: #f77f00;
    }
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}
#input-label {
    font-size: 1.1rem;
}
.input-label {
    font-size: 1.1rem;
}
#multiselect-label {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    width: 40vw;
}
#multiselect-chip {
    margin: 0.5rem;
}
#input-form {
    font-size: 1rem;
}
#select-label {
    font-size: 1.1rem;
}
.input-textfield {
    border: none;
    width: 40vw;
    font-size: 1.1rem;
}

@media screen and (max-width: 768px) {
    .input-textfield {
        width: 100%;
    }
    .input-container {
        width: 80vw;
    }
    #multiselect-label {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    #input-label {
        font-size: 1rem;
    }
    #select-label {
        font-size: 1rem;
    }
}

.webinar-details {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 160px);
    text-align: left;
    font-family: "Noto Sans", sans-serif;
    overflow-x: hidden;
    overflow-y: auto;
}
.webinar-details::-webkit-scrollbar {
    display: none;
}
.det {
    width: 90%;
}
.webinar-name {
    font-size: 2rem;
    font-weight: 600;
    font-family: "Noto Sans Mono", monospace;
    color: #43aa8b;
}
.webinar-domain {
    color: #577590;
}
.webinar-date {
    margin-bottom: 1rem;
    color: #577590;
}
.webinar-desc {
    white-space: pre-wrap;
    text-align: left;
    border-top: solid 1px #118ab2;
    padding-top: 1rem;
}
#webinar-btn {
    text-transform: none;
    width: 40vw;
    background-color: #118ab2;
    color: #fff;
    font-size: 1rem;
}
.webinar-reg-form {
    margin-bottom: 2rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Noto Sans Mono", monospace;
}
.webinar-reg-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 120px);
}
.form-start {
    width: 40vw;
    margin-bottom: 1.3rem;
    font-size: 1.4rem;
    font-weight: 600;
    font-family: "Noto Sans", sans-serif;
}
.loading-page {
    width: 100vw;
    height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.invalid-form {
    width: 100%;
    text-align: center;
    font-family: "Noto Sans Mono", monospace;
    font-weight: 600;
    font-size: 1.4rem;
    color: #ff725e;
}
@media screen and (max-width: 768px) {
    .webinar-reg-wrapper {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    #webinar-btn {
        width: 80vw;
    }
    .form-start {
        width: 80vw;
    }
    .webinar-details {
        min-height: 50vh;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .det {
        width: 80vw;
    }
}

.whole-container {
    width: 100vw;
    height: calc(100vh - 120px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.success-img {
    width: 50vw;
    height: 80vh;
}
.success-text {
    width: 50vw;
    font-family: "Noto Sans Mono", monospace;
    text-align: center;
}
@media screen and (max-width: 768px) {
    .whole-container {
        min-height: calc(100vh - 220px);
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .success-image {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .success-text {
        width: 90vw;
        margin-bottom: 2rem;
    }
    .success-img {
        height: 40vh;
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.img {
    height: calc(100vh - 200px);
}
.home-text {
    text-align: justify;
    width: 60%;
    font-family: "Noto Sans Mono", monospace;
    font-size: 1.1rem;
}
@media screen and (max-width: 768px) {
    .container.image {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .img {
        height: 50vh;
    }
    .home-text {
        width: 90%;
        margin-bottom: 2rem;
    }
}

.webinar-details {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 160px);
    text-align: left;
    font-family: "Noto Sans", sans-serif;
    overflow-x: hidden;
    overflow-y: auto;
}
.webinar-details::-webkit-scrollbar {
    display: none;
}
.det {
    width: 90%;
}
.webinar-name {
    font-size: 2rem;
    font-weight: 600;
    font-family: "Noto Sans Mono", monospace;
    color: #43aa8b;
}
.webinar-domain {
    color: #577590;
}
.webinar-date {
    margin-bottom: 1rem;
    color: #577590;
}
.webinar-desc {
    white-space: pre-wrap;
    text-align: left;
    border-top: solid 1px #118ab2;
    padding-top: 1rem;
}
#webinar-btn {
    text-transform: none;
    width: 40vw;
    background-color: #118ab2;
    color: #fff;
    font-size: 1rem;
}
.webinar-reg-form {
    margin-bottom: 2rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Noto Sans Mono", monospace;
}
.webinar-reg-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 120px);
}
.form-start {
    width: 40vw;
    margin-bottom: 1.3rem;
    font-size: 1.4rem;
    font-weight: 600;
    font-family: "Noto Sans", sans-serif;
}
.loading-page {
    width: 100vw;
    height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.invalid-form {
    width: 100%;
    text-align: center;
    font-family: "Noto Sans Mono", monospace;
    font-weight: 600;
    font-size: 1.4rem;
    color: #ff725e;
}
@media screen and (max-width: 768px) {
    .webinar-reg-wrapper {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    #webinar-btn {
        width: 80vw;
    }
    .form-start {
        width: 80vw;
    }
    .webinar-details {
        min-height: 50vh;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .det {
        width: 80vw;
    }
}

.webinar-details-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 120px);
    width: auto;
}
.certificate-img {
    border: #4f4f4f 1.3px solid;
    height: calc(100vh - 170px);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.cert-img-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cert-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.webinar-name {
    font-size: 1.7rem;
    font-weight: 600;
    font-family: "Noto Sans Mono", monospace;
    color: #43aa8b;
}
.webinar-domain {
    color: #577590;
}
.webinar-date {
    margin-bottom: 1rem;
    color: #577590;
}
.webinar-details-cert::-webkit-scrollbar {
    display: none;
}
.webinar-details-cert {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 160px);
    text-align: left;
    font-family: "Noto Sans", sans-serif;
    overflow-x: hidden;
    overflow-y: auto;
    width: 60%;
}
.reg-name {
    font-weight: bold;
    color: #ff0000;
}
@media screen and (max-width: 768px) {
    .webinar-details-wrapper {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .certificate-img {
        width: 90vw;
        height: auto;
    }
    .webinar-details-cert {
        min-height: 50vh;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 90vw;
    }
    .cert-img-wrapper {
        margin-bottom: 2rem;
    }
    .webinar-details-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.whole-container {
    width: 100vw;
    height: calc(90vh - 130px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.success-img {
    width: 50vw;
    height: 80vh;
}
.success-text {
    width: 50vw;
    font-family: "Noto Sans Mono", monospace;
    text-align: center;
    font-size: 25px;
}
@media screen and (max-width: 768px) {
    .whole-container {
        min-height: calc(100vh - 220px);
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .success-image {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .success-text {
        width: 90vw;
        margin-bottom: 2rem;
    }
    .success-img {
        height: 40vh;
    }
}
.social-icons {
    
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    justify-content: space-evenly;
}

.insta, .github,.discord, .facebook, .linkedin, .youtube {
    height: 10px;
    width: 10px;
    padding: 2px;
    margin: 2px;
    
}
.social-icons > div:hover {
    color: #f77f00;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
