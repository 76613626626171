@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;600&display=swap");

.whole-container {
    width: 100vw;
    height: calc(90vh - 130px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.success-img {
    width: 50vw;
    height: 80vh;
}
.success-text {
    width: 50vw;
    font-family: "Noto Sans Mono", monospace;
    text-align: center;
    font-size: 25px;
}
@media screen and (max-width: 768px) {
    .whole-container {
        min-height: calc(100vh - 220px);
        height: fit-content;
    }
    .success-image {
        height: fit-content;
    }
    .success-text {
        width: 90vw;
        margin-bottom: 2rem;
    }
    .success-img {
        height: 40vh;
    }
}
.social-icons {
    
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    justify-content: space-evenly;
}

.insta, .github,.discord, .facebook, .linkedin, .youtube {
    height: 10px;
    width: 10px;
    padding: 2px;
    margin: 2px;
    
}
.social-icons > div:hover {
    color: #f77f00;
    cursor: pointer;
    user-select: none;
}