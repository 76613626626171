.main-container {
    height: fit-content;
    min-height: calc(100vh - 120px);
    width: 100vw;
}
@media screen and (max-width: 768px) {
    .main-container {
        min-height: calc(100vh - 250px);
    }
}
