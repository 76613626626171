.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}
#input-label {
    font-size: 1.1rem;
}
.input-label {
    font-size: 1.1rem;
}
#multiselect-label {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    width: 40vw;
}
#multiselect-chip {
    margin: 0.5rem;
}
#input-form {
    font-size: 1rem;
}
#select-label {
    font-size: 1.1rem;
}
.input-textfield {
    border: none;
    width: 40vw;
    font-size: 1.1rem;
}

@media screen and (max-width: 768px) {
    .input-textfield {
        width: 100%;
    }
    .input-container {
        width: 80vw;
    }
    #multiselect-label {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    #input-label {
        font-size: 1rem;
    }
    #select-label {
        font-size: 1rem;
    }
}
