@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;600&display=swap");

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.img {
    height: calc(100vh - 200px);
}
.home-text {
    text-align: justify;
    width: 60%;
    font-family: "Noto Sans Mono", monospace;
    font-size: 1.1rem;
}
@media screen and (max-width: 768px) {
    .container.image {
        height: fit-content;
    }
    .img {
        height: 50vh;
    }
    .home-text {
        width: 90%;
        margin-bottom: 2rem;
    }
}
