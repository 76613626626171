@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;600&display=swap");
.webinar-details-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 120px);
    width: auto;
}
.certificate-img {
    border: #4f4f4f 1.3px solid;
    height: calc(100vh - 170px);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.cert-img-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cert-container {
    width: fit-content;
}
.webinar-name {
    font-size: 1.7rem;
    font-weight: 600;
    font-family: "Noto Sans Mono", monospace;
    color: #43aa8b;
}
.webinar-domain {
    color: #577590;
}
.webinar-date {
    margin-bottom: 1rem;
    color: #577590;
}
.webinar-details-cert::-webkit-scrollbar {
    display: none;
}
.webinar-details-cert {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 160px);
    text-align: left;
    font-family: "Noto Sans", sans-serif;
    overflow-x: hidden;
    overflow-y: auto;
    width: 60%;
}
.reg-name {
    font-weight: bold;
    color: #ff0000;
}
@media screen and (max-width: 768px) {
    .webinar-details-wrapper {
        height: fit-content;
    }
    .certificate-img {
        width: 90vw;
        height: auto;
    }
    .webinar-details-cert {
        min-height: 50vh;
        height: fit-content;
        width: 90vw;
    }
    .cert-img-wrapper {
        margin-bottom: 2rem;
    }
    .webinar-details-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}
